/* サイドバー本体 */
.sidebar {
    position: fixed;
    top: 50%;
    right: -280px; /* サイドバーを初期位置で非表示に */
    width: 230px;
    height: 84%;
    background-color: var(--tray-background-color);
    /* color: white; */
    transition: right 0.3s ease;
    z-index: 1000;
    padding: 20px;
    padding-top: 80px;
    text-align: left;
    border-radius: 16px;
    transform: translateY(-50%);
}

/* サイドバー内のリスト色は個別に設定必須 */
body.light-mode .sidebar ul li a {
    color: #333;
}

body.dark-mode .sidebar ul li a {
    color: #fff;
}

/* Sidebar.css */
.close-sidebar {
    background: none; /* 背景を無くす */
    border: none; /* ボーダーを無くす */
    color: var(--icon-color); /* アイコンの色をCSS変数で設定 */
    font-size: 24px; /* アイコンのサイズを調整 */
    cursor: pointer; /* カーソルをポインターに */
    position: absolute; /* サイドバー内での配置を調整 */
    top: 16px; /* 上からの位置を調整 */
    left: 16px; /* 右からの位置を調整 */
}

/* 月次アーカイブのスタイル */
.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 10px;
}

.sidebar ul li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
}

.sidebar ul li a:hover {
    text-decoration: underline;
}

