/* アイコントレーのスタイル */
/* デスクトップ用 */
.icon-tray {
    position: fixed;
    top: 50%;
    right: 16px;
    width: 32px;
    background-color: var(--tray-background-color);
    border-radius: 2px;
    padding: 10px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* サイドバー開閉時と画面縦サイズ変更時のアニメーション */
    /* transition: right 0.3s ease, top 0.3s ease; */
    transition: transform 0.3s ease, right 0.3s ease, top 0.3s ease;
    transform: translateY(-50%); /* 垂直方向に中央揃え */
}

.icon-tray.hidden {
    right: -64px;
    /* opacity: 0; */
    pointer-events: none; /* 非表示時のクリックを無効化 */
    /* visibility: hidden; */
}

.icon-tray.visible {
    right: 16px;
    /* opacity: 1; */
    pointer-events: auto; /* 表示時のクリックを有効化 */
    visibility: visible;
}

/* 区切り線 */
.separator {
    width: 80%; /* 線の幅を調整 */
    height: 1px; /* 線の高さを調整 */
    left: -50px;
    background-color: var(--icon-color); /* 線の色をテーマに合わせる */
    margin: 0px auto; /* 上下に余白を追加し中央揃え */
}

/* 各種リンクアイコン */
.hamburger-menu .icon,
.social-links .icon,
.social-links .social-icon {
    font-size: 24px;
    width: 24px; /* 追加 */
    height: 24px; /* 追加 */
    margin: 30px 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--icon-color); /* アイコンの色をCSS変数から取得 */
    cursor: pointer; /* ポインターを変更 */
}

/* スイカアイコンは個別でスタイル指定必須 */
.social-links a img.social-icon {
    width: 24px;
    height: 24px;
    margin: 30px 0;
    padding: 0; /* 画像アイコンのパディングをリセット */
    display: block;
    filter: invert(0%);
}

/* スイカアイコンはテーマごとに色指定必須 */
body.light-mode .social-links a img.social-icon {
    filter: invert(0%);
}

body.dark-mode .social-links a img.social-icon {
    filter: invert(100%);
}

/* コピーライト情報のモーダルウィンドウのスタイル */
.copyright-modal {
    display: none; /* 初期状態では非表示 */
    position: fixed; /* 画面上に固定 */
    z-index: 1000; /* 他の要素の上に表示 */
    left: 0;
    top: 0;
    width: 100%; /* フル幅 */
    height: 100%; /* フル高さ */
    overflow: auto; /* 必要に応じてスクロール */
    background-color: rgba(0, 0, 0, 0.5); /* 半透明の背景 */
}

.copyright-modal-content {
    background-color: var(--modal-background-color);
    color: var(--modal-text-color);
    margin: 15% auto; /* 中央に配置 */
    padding: 20px;
    /* border: 1px solid var(--modal-text-color); */
    width: 80%;
    max-width: 500px; /* 最大幅を設定 */
    border-radius: 10px; /* 角を丸くする */
    /* box-shadow: 0 5px 15px rgba(0,0,0,0.3); */
}

.close-copyright-button {
    color: var(--modal-text-color);
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-copyright-button:hover,
.close-copyright-button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* モバイル向けスタイル */
@media (max-width: 1299px) {
    /* アイコントレー */
    .icon-tray {
        width: 42px;
        border-radius: 4px;
    }
    
    /* 各種リンクアイコン */
    .hamburger-menu .icon,
    .social-links .icon,
    .social-links .social-icon {
        font-size: 40px;
        width: 40px;
        height: 40px;
        margin: 40px 0;
    }
    
    .social-links a img.social-icon {
        width: 40px;
        height: 40px;
        margin: 40px 0;
    }
}