/* ヘッダー全体のスタイル */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 1000;
    transition: top 0.3s ease;
}

/* 表示テーマ切り替えスイッチ */
.theme-switch-wrapper {
    position: absolute;
    /* position: fixed; */
    top: 10px;
    left: 10px;
    background-color: transparent;
    z-index: 1000;
    margin: 20px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: left;
}

.theme-switch {
    position: relative;
    display: inline-block;
    width: 72px;
    height: 34px;
    margin: 10px;
}

.theme-switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #555;
}

input:checked + .slider:before {
    transform: translateX(38px);
}

.sun-icon, .moon-icon {
    color: var(--icon-color);
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.4s ease-in-out;
}

.sun-icon {
    right: 12px;
    display: none;
}

.moon-icon {
    left: 12px;
    display: none;
}

input:checked + .slider .sun-icon {
    display: none;
}

input:not(:checked) + .slider .sun-icon {
    display: block;
}

input:checked + .slider .moon-icon {
    display: block;
}

input:not(:checked) + .slider .moon-icon {
    display: none;
}

input:checked + .slider .light-mode {
    opacity: 0;
}

input:not(:checked) + .slider .dark-mode {
    opacity: 0;
}

/* アイコントレーのトグルスイッチ */
.header .toggle-switch {
    position: absolute;
    top: 10px; /* ヘッダーの上端からの位置 */
    right: 16px;
    height: 52px;
    width: 52px;
    margin: 20px 0;
    padding: 10px;
    background-color: var(--tray-background-color);
    border-radius: 2px;
    border: none; /* 枠線をなしに */
    cursor: pointer; /* ポインターを変更 */
    z-index: 999; /* アイコントレーより前面に表示 */
    display: flex; /* フレックスボックスを使用 */
    align-items: center; /* 垂直方向に中央揃え */
    justify-content: center; /* 水平方向に中央揃え */
    transition: background-color 0.5s ease, color 0.5s ease;
}

/* トグルスイッチアイコンのスタイル */
.header .toggle-switch .icon {
    font-size: 24px; /* アイコンのサイズ */
    color: var(--icon-color); /* アイコンの色をテーマに応じて設定 */
    transition: transform 0.3s ease, color 0.5s ease; /* 切り替え時のアニメーション */
}

/* トグルスイッチがアクティブなときのスタイル */
.header .toggle-switch.active .icon {
    transform: rotate(90deg); /* 回転アニメーション */
}

@media (max-width: 1299px) {
    /* モバイルデバイスのみスクロールでヘッダーを格納 */
    .header.hidden {
        top: -100px;
    }

    /* アイコントレートグルスイッチのスタイル */
    .header .toggle-switch {
        height: 62px;
        width: 62px;
        border-radius: 4px;
    }

    .header .toggle-switch .icon {
        font-size: 32px;
    }
}