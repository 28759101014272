:root {
    --icon-color-light: #000;
    --icon-color-dark: #fff;
}

.mode {
    position: absolute;
    width: 100%;
    line-height: 34px;
    text-align: center;
}

.light-mode {
    display: block;
    color: black;
    left: 0px;
    transition: opacity 0.8s;
}

.dark-mode {
    display: block;
    color: white;
    right: 0px;
    transition: opacity 0.8s;
}

/* ライトモードのスタイル */
body.light-mode {
    --icon-color: var(--icon-color-light);
    background-color: #fff;  /* 背景色 Feeder実装後はfffでも可*/
    color: #000;             /* 文字色 */
    border-color: #000;      /* 枠線の色 */
    /* --top-background: #ddd; */
    --top-text: #000;
    --tray-background-color: #eee;
    --modal-background-color: #aaa; /* モーダルの背景色 */
    --modal-text-color: #000;
    /* --feeder-background-color: #bbb; */
    transition: background-color 0.5s, color 0.5s, border-color 0.5s, --top-background 0.5s, --top-text 0.5s, --tray-background-color 0.5s, --modal-background-color 0.5s, --modal-text-color 0.5s;
}

/* ダークモードのスタイル */
body.dark-mode { 
    --icon-color: var(--icon-color-dark);
    background-color: #333;  /* 背景色 */
    color: #fff;             /* 文字色 */
    border-color: #fff;      /* 枠線の色 */
    /* --top-background: #333; */
    --top-text: #fff;
    --footer-background: #333; /* ダークモード時の背景色 */
    --tray-background-color: #444;
    --modal-background-color: #555; /* モーダルの背景色 */
    --modal-text-color: #fff; 
    /* --feeder-background-color: #444; */
    transition: background-color 0.5s, color 0.5s, border-color 0.5s, --top-background 0.5s, --top-text 0.5s, --tray-background-color 0.5s, --modal-background-color 0.5s, --modal-text-color 0.5s;
}

/* bodyの表示設定 */
html, body {
    height: 100%; /* 必要なら100vhに変更 */
    margin: 0;
    padding: 0;
    display: flex;
    min-height: 100vh; /* ビューポートの最小高さとして100vhを設定 */
    width: 100%;
    /* flex-direction: column; */
    transition: background-color 0.5s, color 0.5s; /* 背景色と文字色の遷移効果を追加 */
}

body {
    flex: 1 0 auto; /* コンテンツがページの高さを満たすように設定 */
    position: relative; /* 相対位置指定 */
}

/* メインコンテンツの表示設定 */
main {
    flex: 1; /* これによってメインコンテンツが残りのスペースをすべて使う */
    display: flex;
    justify-content: center; /* メインコンテンツを中央に配置 */
    align-items: flex-start; /* メインコンテンツを上端に配置 */
}

.container {
    width: 100%;
    max-width: 800px; /* メインコンテンツの最大幅を設定 */
    display: flex;
}

.content {
    padding: 20px;
    box-sizing: border-box;
    display: none; /* メインコンテンツを初期状態では非表示 */
    margin-top: 120px; /* 各コンテンツの上部にマージンを追加 */
    text-align: left;
}

/* モバイル向けスタイル */
@media (max-width: 1299px) {
    .content {
        margin-top: 80px; /* 各コンテンツの上部にマージンを追加 */
    }
}

.article {
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
}

.article li {
    line-height: 1.6; /* 行間を調整 */
}

.article:last-child {
    border-bottom: none; /* 最後の記事にはボーダーを表示しない */
}

/* 以下のコードはコンテンツの切り替え表示に必須 */
#home-content {
    display: block;
}

#about-content {
    display: block;
}

#contact-content {
    display: block;
}