.back-to-top {
    position: fixed;
    bottom: 32px;
    right: 16px;
    width: 52px;
    height: 52px;
    /* padding: 10px; */
    /* padding: 12px 20px; */
    background-color: var(--tray-background-color);
    color: var(--top-text);
    border: none;
    border-radius: 2px;
    cursor: pointer;
    opacity: 0;
    display: none;
    z-index: 1000;
    font-size: 36px;
    transition: opacity 0.2s ease-in-out, background-color 0.5s ease, color 0.5s ease;
}

.back-to-top.show {
    display: block;
    opacity: 1;
    pointer-events: auto; /* 表示時にはクリックできるようにする */
}

.back-to-top .fa-arrow-icon {
    /* font-size: 52px; */
    color: var(--icon-color);
    transition: color 0.5s ease;
}

/* モバイル用上に戻るボタンスタイル */
@media (max-width: 1299px) {
    .back-to-top {
        width: 62px;
        height: 62px;
        font-size: 36px;
        border-radius: 4px;
    }
}
