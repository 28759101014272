/* アバウトページコンテンツの表示設定 */
.about-flex {
    display: flex; /* フレックスコンテナを有効化 */
    align-items: center; /* 子要素を中央揃えに設定 */
    justify-content: space-between; /* 子要素間に均等なスペースを配置 */
    flex-direction: row;
    width: 100%;
    /* max-width: 800px; */
    flex-wrap: wrap; /* 小さい画面でも子要素が折り返されるように */
}

.profile-section {
    flex: 1; /* 画像とキャプションのセクションに空間を割り当て */
    display: flex; /* フレックスボックスを有効化 */
    flex-direction: column; /* 子要素を垂直方向に並べる */
    align-items: center; /* 画像とキャプションを左寄せに */
}

.profile-img {
    min-width: 400px;
    max-width: 60%;
    /* width: 400px; */
    height: auto; /* 高さは自動で調整 */
    margin-bottom: 8px; /* 下にマージンを設定 */
}

.photo-caption {
    font-style: italic; /* イタリック体にする */
    font-size: 14px;
    color: #757575; /* 薄いグレー色 */
}

.career {
    max-width: 100%;
    /* width: 100%; */
    flex: 2; /* 残りのスペースをフレックスアイテムが埋める */
    padding: 8px;
    font-size: 12px;
    font-family: Arial, sans-serif;
}

.career h2 {
    margin-bottom: 8px; /* タイトル下のマージン */
    font-size: 24px; /* タイトルのフォントサイズ */
}

.career table {
    width: 100%; /* テーブルの幅をセクションに合わせる */
    border-collapse: collapse; /* ボーダーのダブル線を避ける */
}

.career td {
    padding: 8px; /* セルのパディング */
    border-bottom: 1px solid #ddd; /* 行間に薄い線を描画 */
}

.career td:first-child {
    font-weight: bold; /* 日付を太字に */
    color: #a7a7a7; /* 日付の色を少し濃く */
    white-space: nowrap; /* 日付を一行で表示 */
}

.career td:last-child {
    padding-left: 12px; /* 日付と内容の間隔 */
}

/* モバイル向けスタイル */
@media (max-width: 1299px) {
    .profile-img {
        min-width: 80px;
        max-width: 800px;
        /* width: 800px; */
    }

    .photo-caption {
        font-style: italic; /* イタリック体にする */
        font-size: 24px;
        color: #757575; /* 薄いグレー色 */
    }

    .career {
        max-width: 100%;
        /* width: 100%; */
        font-size: 32px;
    }

    .career h2 {
        margin-bottom: 8px; /* タイトル下のマージン */
        font-size: 48px; /* タイトルのフォントサイズ */
    }
}