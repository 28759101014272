/* 問い合わせフォーム */
#contact-content {
    width: 100%;
    justify-content: center;
}

/* 個別の入力ボックス幅 */
.inquiry-name {
    min-width: 300px;
    width: 50%;
    margin: 10px 10px 0px 10px;
}

.inquiry-email {
    min-width: 300px;
    width: 50%;
    margin: 10px 10px 0px 10px;
}

.inquiry-subject {
    min-width: 600px;
    width: 100%;
    margin: 10px 10px 0px 10px;
}

.inquiry-message {
    min-width: 600px;
    width: 100%;
    margin: 10px 10px 0px 10px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
}

.form-group input,
.form-group textarea {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-note {
    font-size: 0.9em;
    margin: 0px 10px 0px 10px;
}

button[type="submit"] {
    background-color: #414141;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button[type="submit"]:hover {
    background-color: #414141;
}

button[type="button"] {
    background-color: #414141;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button[type="button"]:hover {
    background-color: #414141;
}

@media (max-width: 1299px) {
    .form-group input,
    .form-group textarea {
        font-size: 24px;
    }

    .form-group label {
        font-size: 24px;
    }

    button[type="button"] {
        font-size: 24px;
    }

    .form-note {
        font-size: 1.2em;
    }
}

/* モーダルウィンドウのスタイリング */
.modal {
    display: none; /* 最初は非表示 */
    position: fixed; /* 画面上に固定 */
    z-index: 1; /* コンテンツの上に表示 */
    left: 0;
    top: 0;
    width: 100%; /* フル幅 */
    height: 100%; /* フル高さ */
    overflow: auto; /* 必要に応じてスクロールバーを表示 */
    background-color: rgb(0,0,0); /* 背景色 */
    background-color: rgba(0,0,0,0.4); /* 黒色の背景を少し透明に */
}

.modal-content {
    background-color: #eeeeee;
    color: #000000; /* 濃いグレーの文字色、ダークモード時でも見やすい */
    margin: 15% auto; /* 15% 上部から自動的に中央に配置 */
    padding: 20px;
    border: 1px solid #888;
    width: 60%;

    border-radius: 10px; /* 角の丸み */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 影を追加 */
    position: relative; /* 位置を指定 */
    z-index: 10; /* 背景より前面に */
}

.close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-button:hover,
.close-button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}